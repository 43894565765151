import React, {useEffect, useState, Fragment, useRef} from 'react';
import OrderHeader from "../OrderHeader/OrderHeader";
import {useSelector} from "react-redux";
import {Button, Row, Col, Container, Form, Alert, Spinner, Badge, Accordion} from 'react-bootstrap';
import {apiService, baseUrl, currencySymbol, Icons, shopName} from "../../constent";
import moment from "moment"
import {useNavigate} from "react-router-dom";
import Footer from "../Footer/Footer";

const Confirmation = () => {
    const shopDetails = useSelector((state) => state.shopDetails);
    const [isLoading, setIsLoading] = useState(false);

    const [setting, setSetting] = useState({});
    const [order, setOrder] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const getOrder = async () => {
            setIsLoading(true);
            const response = await apiService.getOrder();
            if (response.status === 200){

                setOrder({...response.data.order});
                setSetting(response.data.setting);
                setIsLoading(false)
            } else {
                setIsLoading(false)
            }
        };
        getOrder();
    }, []);
    const onSubmitAction = async () => {
        const id =  localStorage.getItem("returnExchangeId")
        const response = await apiService.submitActionPDF(id);
    }

    const onLogOut = () => {
        localStorage.removeItem("collectedData");
        localStorage.removeItem("r&eAppToken");
        localStorage.removeItem("returnAndExchangeData");
        localStorage.removeItem("returnExchangeId")
        navigate(`${baseUrl}/${shopName}/login`);
    };
    return (
        <div className="re-order-content re-confirmation">
            <OrderHeader  shopDetails={shopDetails} isDisplay={false} onLogOut={onLogOut} logo={setting.logo}/>
            <div className="re-content-area">
                <div className="re-main-content">
                    <Container>
                        <Row className={"justify-content-center d-flex align-items-center"}>
                            <Col xs={12} md={"auto"}>
                                <div className={"re-card"}>
                                    <div className={"re-card-header"}>
                                        <div className="re-order-no"><p>Order #{order?.order_number}</p></div>
                                        <div className="re-order-title"><h2>Thanks for your return, </h2></div>
                                        <div className="re-order-title"><h2>please find the label below.</h2></div>
                                        <div className={"re-return-instructions"}>The address label and return instructions are emailed to <a href={`mailto:${order?.email}`}>{order?.email}</a></div>
                                    </div>
                                    <div className={"re-card-content"}>
                                        <Button variant={"primary"} size={"lg"} className={"w-100"} onClick={onSubmitAction}>Download address label</Button>
                                        <div className={"re-return-days"}>
                                            <p>It takes around {setting?.process_days || 0} business days to process your </p>
                                            <p>return after you hand in your package.</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer/>
            </div>

        </div>
    );
};

export default Confirmation;