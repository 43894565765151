import axios from "axios";
import {baseUrl, getToken, shopName} from "./constent";
const baseUrlAPI = 'https://returns.notsellingliquid.com/return-exchange';
const instance = axios.create();


instance.interceptors.request.use(function (config) {
  config.headers["Authorization"] = `Bearer ${getToken()}`;
  return config;

});

export class ApiService {

  async getData(url, header) {
    const config = {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        ...header || {}
      }
    };
    let resData = '';
    let response = '';
    await instance.get(url, config).then((res) => {
      if (res.data.status === 200) {
        response = res.data;
      } else if(res.data.status === 423){
          localStorage.removeItem('r&eAppToken');
          localStorage.removeItem("r&eAppToken");
          localStorage.removeItem("returnAndExchangeData");
          window.open(`${baseUrl}/${shopName}/login`,"_self");
          response = res.data
      }else {
          response = res.data
      }
    }).catch((e) => {
      resData = e && e.response && e.response.data;
    });
    return resData || response
  }

  async postData(url, data, isFormData, header) {
    const config = {
      headers: {
        'content-type': 'application/json',
        ...header || {}
      }
    };
    let resData = '';
    let response = '';
    await instance.post(url, data, config).then((res) => {
      if (res.data.status === 200) {
        response = res.data;
      } else if(res.data.status === 400) {
        response = res.data
      } else if(res.data.status === 423){
        localStorage.removeItem('r&eAppToken');
        localStorage.removeItem("r&eAppToken");
        localStorage.removeItem("returnAndExchangeData");
        window.open(`${baseUrl}/${shopName}/login`,"_self");
        response = res.data
      }else {
        response = res.data
      }
    }).catch((e) => {
      resData = e.response.data;
    });
    return resData || response
  }

  async getSetting(payload) {
    return await this.postData(`${baseUrlAPI}/api/v1/setting`, payload)
  }
  async login(payload) {
    return await this.postData(`${baseUrlAPI}/api/v1/login`, payload)
  }
  async getOrder() {
    return await this.postData(`${baseUrlAPI}/api/v1/order`)
  }
  async getProduct(payload) {
    return await this.postData(`${baseUrlAPI}/api/v1/product`, payload)
  }
  async submitRequest(payload) {
    return await this.postData(`${baseUrlAPI}/api/v1/submit`, payload)
  }

  async submitActionPDF(payload) {
    return window.open(`${baseUrlAPI}/api/download?id=${payload}`, "_blank")
  }

}

