import React, {useEffect, useState, Fragment, useRef} from 'react';
import {Button, Container, Form, Alert, Spinner, Badge, Accordion} from 'react-bootstrap';
import {useSelector} from "react-redux";
import OrderHeader from "../OrderHeader/OrderHeader";
import Footer from "../Footer/Footer";
import {apiService, baseUrl, currencySymbol, Icons, shopName} from "../../constent";
import moment from "moment"
import {useNavigate} from "react-router-dom";
const UseScrollStop = (callback, isMulti) => {
    const scrollRef = useRef( null);
    let scrollTimeout;
    const handleScroll = () => {
        if (scrollTimeout) {
            clearTimeout(scrollTimeout);
        }
        scrollTimeout = setTimeout(() => {
            callback();
        }, 150); // Set your desired delay here
    };

    useEffect(() => {
        const node = scrollRef.current;
        if (node) {
            node.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (node) {
                node.removeEventListener("scroll", handleScroll);
            }
        };
    }, [callback]);

    return scrollRef;
};

const Order = () => {
    const shopDetails = useSelector((state) => state.shopDetails);
    const navigate = useNavigate();
    const [order, setOrder] = useState({});
    const [returnExchangeOptions, setReturnExchangeOptions] = useState([]);
    const [returnExchangeReasons, setReturnExchangeReasons] = useState([]);
    const [setting, setSetting] = useState({});
    const [selectedProduct, setSelectedProduct] = useState({});
    let [selectedOptions, setSelectedOptions] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCallApi, setIsLoadingCallApi] = useState(false);
    const [isLoadingCallApiSub, setIsLoadingCallApiSub] = useState(false);
    const [additionalInfoLabel, setAdditionalInfoLabel] = useState("");
    const [status, setStatus] = useState("");
    const [returnAndExchangeData, setReturnAndExchangeData] = useState({
        currencyCode: "",
        returnCount: 0,
        exchangeCount: 0,
        rePriceTotalDiscounted: 0,
        returnProductPriceTotal: 0,
        exchangeProductPriceTotal: 0
    });

    let scrollTimeoutMulti;
    const handleScroll = (i) => {
        if (scrollTimeoutMulti) {
            clearTimeout(scrollTimeoutMulti);
        }
        scrollTimeoutMulti = setTimeout(() => {
            onScrollSubFilter(i)
        }, 150); // Set your desired delay here
    };

    useEffect(() => {
        const getOrder = async () => {
            setIsLoading(true);
            const response = await apiService.getOrder();
            if (response.status === 200){
                let newLineItems = [...response.data.order.line_items];
                const collectedData = JSON.parse(localStorage.getItem("collectedData")) || [];
                const returnAndExchangeData = JSON.parse(localStorage.getItem("returnAndExchangeData")) || {currencyCode: "", returnCount: 0, exchangeCount: 0, rePriceTotalDiscounted: 0, returnProductPriceTotal: 0, exchangeProductPriceTotal: 0};
                let newLineItemsData = [];
                newLineItems.map((x, ind) => {
                    if(x.quantity){
                        Array.from(Array(x.quantity)).map((_, i) => {
                            let fulfillment_status = (i + 1) > x.fulfillable_quantity;
                            if(fulfillment_status){
                                newLineItemsData.push({...x, quantity: 1,  isFulFill: true,})
                            } else {
                                newLineItemsData.push({...x, quantity: 1, isFulFill: false,})
                            }

                        })
                    } else {
                        newLineItemsData.push(x)
                    }
                });

                newLineItemsData = newLineItemsData.map((x, i) => {
                    return { ...x, return_exchange_product_id: i + 1}
                });
                newLineItemsData = newLineItemsData.map((x, ind) => {
                    let findSubmitted = (response.data.request || []).find((y) => y.line_item_id == x.id && x.return_exchange_product_id == y.return_exchange_product_id && (y.type == 1 || y.type== 2));
                    const index  = (collectedData|| []).findIndex((y) => y.id == x.id && x.return_exchange_product_id == y.return_exchange_product_id);
                    let oldObj = {};
                    if(index !== -1){
                        oldObj = collectedData[index];
                    }

                    return index !== -1 ? {...x, ...oldObj, type: 0} : {
                        ...x, isExchange: findSubmitted?.line_item_id ? findSubmitted?.type == 2 : true ? x?.isExchange: false,
                        isReturn: findSubmitted?.line_item_id ? findSubmitted?.type == 1 : true ? x?.isReturn : false,
                        type: findSubmitted?.type ? findSubmitted?.type : 0,}

                });
                setReturnAndExchangeData(returnAndExchangeData);
                setOrder({...response.data.order, line_items: newLineItemsData.sort((a, b) =>  b.isFulFill - a.isFulFill || a.type - b.type || a.isExchange - b.isExchange || a.isReturn - b.isReturn)});
                setReturnExchangeOptions(response.data.return_exchange_options);
                setReturnExchangeReasons(response.data.return_exchange_reasons);
                setSetting(response.data.setting);
                setStatus(response.data.status);
                setIsLoading(false)
            } else {
                setIsLoading(false)
            }
        };
        getOrder();
    }, []);

    const onModalOpen = (record) => {
        if(selectedProduct.return_exchange_product_id !== record.return_exchange_product_id){
            const findVariant = (record?.variants || []).find((x) => x.id === record.variant_id);
            const findImage = (record?.images || []).find((x) => x.id === findVariant.image_id);
            setSelectedProduct({...record, image: findImage?.src ? findImage?.src : record.image})
            setSelectedOptions({})
        } else {
            setSelectedProduct({})
            setSelectedOptions({})
        }
    };

    const handleClickOutside = event => {
        setSelectedProduct({})
        setSelectedOptions({})
    };

    const onLogOut = () => {
        localStorage.removeItem("collectedData");
        localStorage.removeItem("r&eAppToken");
        localStorage.removeItem("returnAndExchangeData");
        navigate(`${baseUrl}/${shopName}/login`);
    };

    const onReview = () => {
        navigate(`${baseUrl}/${shopName}/summary`);
    };

    const onClickOption = async (record) => {
        if(record.filter_type === 2){
            setSelectedOptions(record)
            setIsLoadingCallApi(true);
            const response = await apiService.getProduct({tag: record.search_tag});
            if(response.status === 200){
                setSelectedOptions({...record, products: response.data.products, next: response.data.next, pages: response.data.pages});
                setIsLoadingCallApi(false);
            } else {
                setIsLoadingCallApi(false);
            }
        } else if(record.filter_type === 3){
            setSelectedOptions(record)
            setIsLoadingCallApi(true);
            let sub_filter = record.sub_filter ? JSON.parse(record.sub_filter) : [];
            sub_filter.forEach(async (x, i) => {
                const response = await apiService.getProduct({tag: x.tag});
                if(response.status === 200){
                    let obj = {...x, products: response.data.products, next: response.data.next, pages: response.data.pages};
                    sub_filter[i] = obj;
                }
                if(sub_filter.length === i+1){
                    setSelectedOptions({...record, sub_filter: sub_filter})
                    setIsLoadingCallApi(false);
                }
            });
        } else {
            setSelectedOptions(record)
        }
    };

    const onOpenSubOption = async(record, index) => {
        let clone = [...selectedOptions.sub_filter];
        if(!clone[index]?.index){
            let obj = {...clone[index], index: index+1};
            clone[index] = obj;
            setSelectedOptions({...selectedOptions, sub_filter: clone})
        } else {
            let obj = {...clone[index], index: ''};
            clone[index] = obj;
            setSelectedOptions({...selectedOptions, sub_filter: clone})
        }
    };

    const onClickReasons = (record, type) => {
        let obj = {...selectedOptions};
        if(type === "subReason"){
            obj = {...obj, selectedReasons:{...selectedOptions.selectedReasons, subReason: record, isDisplay: record.is_additional_info == 0 ? true : false}}
        } else {
            let sub_reason = record.sub_reason ? JSON.parse(record.sub_reason) : []
            obj = {...obj, selectedReasons:{...record, sub_reason: sub_reason, isDisplay: record.is_additional_info == 0 && sub_reason.length > 0 ? true : false}}
        }
        setSelectedOptions(obj);
    };

    const onBackOption = (type) => {
        if(type === "option"){
            setSelectedOptions({});
        }else if(type === "variant"){
            setSelectedOptions({...selectedOptions, selectedVariant: {}});
        }else if(type === "product"){
            setSelectedOptions({...selectedOptions, selectedProduct: {}});
        } else {
            setSelectedOptions({})
        }

    };

    const onBackReasons = () => {
        if(selectedOptions?.selectedReasons?.id && !selectedOptions?.selectedReasons?.subReason?.label){
            setSelectedOptions({...selectedOptions, selectedReasons: {}});
        }  else {
            setSelectedOptions({...selectedOptions, selectedReasons:{}})
        }
    };

    const onSelectVariant = (record) => {
        setSelectedOptions({...selectedOptions, selectedVariant: record});
    };

    const priceCalculation = (data) => {
        let  lineItems = [...data];
        lineItems = lineItems.map((x) => {
            if(x?.type == 0 || !x?.type){
                let isDisplayOptionAndReason = (x?.optionAndReason?.selectedReasons?.id || (x?.optionAndReason?.selectedReasons?.id && x?.optionAndReason?.selectedVariant?.id)) ? true : false;
                let isReturn = (x?.optionAndReason?.selectedReasons?.id && !x?.optionAndReason?.selectedVariant?.id) ? true : false;
                let isExchange = (x?.optionAndReason?.selectedReasons?.id && x?.optionAndReason?.selectedVariant?.id) ? true : false;
                return {...x, isDisplayOptionAndReason, isReturn, isExchange}
            } else {
                return {...x}
            }
        });

        let reCount = lineItems.filter((x) => x?.type == 0 ? x?.isReturn === true : x?.type != 0 ? false : x?.isReturn === true).length;
        let exCount = lineItems.filter((x) => x?.type == 0 ? x?.isExchange === true : x?.type != 0 ? false : x?.isExchange === true).length;

        let rePriceTotalDiscounted = 0;
        let currencyCode = "";
        let returnProductPriceTotal = 0;
        let exchangeProductPriceTotal = 0;
        let newlineItems = [];
        lineItems.forEach((x) => {
            if(x?.type == 0 || !x?.type){
                if(x?.isReturn === true || x?.isExchange === true){
                    currencyCode = x?.price_set?.presentment_money?.currency_code;
                    if(x.discount_allocations.length > 0){
                        const discountAllocationPrice = x.discount_allocations[0].amount;
                        rePriceTotalDiscounted = rePriceTotalDiscounted + (parseFloat(x.price) - parseFloat(discountAllocationPrice));
                        returnProductPriceTotal = returnProductPriceTotal + (parseFloat(x.price) - parseFloat(discountAllocationPrice));
                    } else {
                        returnProductPriceTotal = returnProductPriceTotal + parseFloat(x.price);
                    }

                    if(x?.isExchange === true){
                        exchangeProductPriceTotal = exchangeProductPriceTotal + parseFloat(x.optionAndReason.selectedVariant.price);
                    }
                    newlineItems.push({
                        id: x.id,
                        return_exchange_product_id: x.return_exchange_product_id,
                        optionAndReason: x.optionAndReason,
                        isReturn: x.isReturn,
                        isExchange: x.isExchange,
                        isDisplayOptionAndReason: x.isDisplayOptionAndReason,
                    })
                }
            }
        });

        let objData = {
            ...returnAndExchangeData,
            currencyCode: currencyCode,
            returnCount: reCount,
            exchangeCount: exCount,
            rePriceTotalDiscounted: rePriceTotalDiscounted,
            returnProductPriceTotal: returnProductPriceTotal,
            exchangeProductPriceTotal: exchangeProductPriceTotal
        };

        setReturnAndExchangeData(objData);
        localStorage.setItem("collectedData", JSON.stringify(newlineItems));
        localStorage.setItem("returnAndExchangeData", JSON.stringify(objData));
        setAdditionalInfoLabel('');
        return lineItems;
    }

    const onSelectReasons = (x, type) => {
        let obj = {...selectedOptions};
        if(type === "subReason"){
            obj = {...obj, selectedReasons: {...selectedOptions.selectedReasons, subReason: x}}
        } else {
            obj = {...obj, selectedReasons: x}
        }
        setSelectedOptions(obj);
        let  lineItems = [...order?.line_items] || [];
        const index = lineItems.findIndex((x) => x.id == selectedProduct.id && x.return_exchange_product_id == selectedProduct.return_exchange_product_id);

        if(index !== -1){
            lineItems[index] = {...lineItems[index], optionAndReason: obj}
        }
        const priceCalculationData = priceCalculation(lineItems)
        setOrder({...order, line_items: priceCalculationData,});
        setSelectedOptions({});
        setSelectedProduct({});
    };

    const onSelectProduct = (x) => {
        let obj = {...selectedOptions, selectedProduct: x};
        setSelectedOptions(obj)
    };

    const onScroll = async () => {
        if(selectedOptions?.pages?.hasNextPage && isLoadingCallApi === false){
            setIsLoadingCallApi(true);
            const response = await apiService.getProduct({tag: selectedOptions.search_tag, cursor: selectedOptions.next});
            if(response.status === 200){
                let cloneProduct = [...selectedOptions.products];
                cloneProduct = [...cloneProduct, ...response.data.products];
                setSelectedOptions({...selectedOptions, products: cloneProduct, next: response.data.next, pages: response.data.pages});
                setIsLoadingCallApi(false)
            } else {
                setIsLoadingCallApi(false)
            }
        }
    }

    const scrollRef = UseScrollStop(onScroll, false);

    const onScrollSubFilter = async (i) => {
        if(selectedOptions?.sub_filter[i]?.pages?.hasNextPage && isLoadingCallApiSub === false){
            setIsLoadingCallApiSub(true);
            const response = await apiService.getProduct({tag: selectedOptions?.sub_filter[i].tag, cursor:selectedOptions?.sub_filter[i]?.next});
            if(response.status === 200){
                let sub_filter = [...selectedOptions?.sub_filter];
                let cloneProduct = [...selectedOptions?.sub_filter[i].products];
                cloneProduct = [...cloneProduct, ...response.data.products];
                sub_filter[i] = {...sub_filter[i], products:cloneProduct, next: response.data.next, pages: response.data.pages};
                setSelectedOptions({...selectedOptions, sub_filter: sub_filter});
                setIsLoadingCallApiSub(false)
            } else {
                setIsLoadingCallApiSub(false)
            }
        }
    }

    const onRemove = (record, index) => {
        let lineItems = [...order?.line_items] || [];
        let obj = {...record};
        ['optionAndReason', 'isReturn', 'isExchange', 'isDisplayOptionAndReason'].forEach(e => delete obj[e]);
        lineItems[index] = obj;
        const priceCalculationData = priceCalculation(lineItems);
        setOrder({...order, line_items: priceCalculationData})

    };

    const FilterTypeOne = (variants, product) => {
        const renderVariants = (x, i, isProductOutOfStock) => {
            let newPrice = parseFloat(x.price) - parseFloat(selectedProduct.price);
            let newPriceLess = parseFloat(selectedProduct.price) - parseFloat(x.price);
            let allowMaxExpensive = setting.allow_max_expensive == 0 && newPrice > 0;
            return(
                <li key={`FilterTypeOne-${i}`} className={`${isProductOutOfStock ? "is-disabled" : allowMaxExpensive ? "is-disabled" : (product.variant_id == x.id || selectedProduct.variant_id == x.id) ? "is-disabled" :''}`} onClick={isProductOutOfStock ? null : allowMaxExpensive ? null : (product.variant_id == x.id || selectedProduct.variant_id === x.id) ? null : () => onSelectVariant(x)}>
                    <div className={"re-option-variant"}>
                        <span className={"re-option-variant-title"}>
                            {product.options.map((y, ind) =>{return(<React.Fragment key={ind}>{y.name}{(ind + 1) == product.options.length ? ": ": " / "}</React.Fragment>)})}{x.title}
                        </span>
                        {(product.variant_id == x.id || selectedProduct.variant_id == x.id) ? <React.Fragment><Badge bg="light">Current</Badge></React.Fragment> :''}
                        {isProductOutOfStock ? <React.Fragment><Badge bg="light">Sold out</Badge></React.Fragment> :''}
                    </div>
                    <div className={"re-option-variant"}>
                        <span className={"re-option-variant-price"}>{currencySymbol[shopDetails.currency]}{x.price}</span>
                        {
                            allowMaxExpensive ? "" : newPrice > 0 ? <React.Fragment><Badge bg="bg-danger" className={isProductOutOfStock ? "active" : ""}>+ {currencySymbol[shopDetails.currency]}{newPrice.toFixed(2)}</Badge></React.Fragment> : ''
                        }
                        {
                             0 < newPriceLess  ? <React.Fragment><Badge bg="bg-success" className={"active"}>- {currencySymbol[shopDetails.currency]}{newPriceLess.toFixed(2)}</Badge></React.Fragment> : ''
                        }
                        {
                            isProductOutOfStock || (product.variant_id == x.id || selectedProduct.variant_id == x.id) ? <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> : <span className={"re-option-arrow"}>{Icons.continueIcon}</span>
                        }

                    </div>
                </li>
            )
        }
        return(
            <Fragment>
                {
                    selectedOptions?.selectedVariant?.id ?  FilterTypeFour() :
                        <div className="re-item-wizard-sub-option">
                            {(variants || []).map((x, i) => {
                                let isProductOutOfStock = setting?.inventory_threshold >= x?.inventory_quantity;
                                return(
                                    setting.hide_out_of_stock === 0 ? renderVariants(x, i, isProductOutOfStock) : setting.hide_out_of_stock === 1 && !isProductOutOfStock ? renderVariants(x, i, isProductOutOfStock) : ""
                                )
                            })}
                        </div>
                }

            </Fragment>
        )
    }

    const FilterTypeTwo = () => {
        const renderProducts = (x, i, isProductOutOfStock) => {
            let newPrice = parseFloat(x.variants[0].price) - parseFloat(selectedProduct.price);
            let newPriceLess = parseFloat(selectedProduct.price) - parseFloat(x.variants[0].price);
            let allowMaxExpensive = setting.allow_max_expensive == 0 && newPrice > 0;
            return (
                <li className={`FilterTypeTwoImg ${isProductOutOfStock ? "is-disabled" : allowMaxExpensive ? "is-disabled" : ""}`} onClick={isProductOutOfStock ? null : allowMaxExpensive ? null :() => onSelectProduct(x)}>
                    <div className={"re-product-details"}>
                        <div className={"re-product-img"}>
                            <img src={x?.image?.src} alt={x?.image?.alt} />
                        </div>
                        <div className={"re-product-detail-content"}>
                            <h2>{x.title}</h2>
                            <div className="re-product-price">
                                <span>{currencySymbol[shopDetails.currency]}{x?.variants[0]?.price}</span>
                                {
                                    allowMaxExpensive ? "" : newPrice > 0 ? <React.Fragment><Badge bg="danger" className={isProductOutOfStock || allowMaxExpensive ? "active" : ''} >+ {currencySymbol[shopDetails.currency]}{newPrice.toFixed(2)}</Badge>&nbsp;</React.Fragment> : ''
                                }
                                {
                                    0 < newPriceLess  ? <React.Fragment><Badge bg="bg-success" className={"active"}>- {currencySymbol[shopDetails.currency]}{newPriceLess.toFixed(2)}</Badge></React.Fragment> : ''
                                }
                            </div>
                        </div>
                    </div>
                    <div className={"re-product-action"}>
                        {isProductOutOfStock ? <div className="re-product-out-of-stock"><Badge bg="light">Sold out</Badge></div> : ""}
                        <span>{Icons.continueIcon}</span>
                    </div>
                </li>
            )
        }
        return(
            <Fragment>

                    {
                         selectedOptions?.selectedProduct?.id ? FilterTypeOne(selectedOptions?.selectedProduct?.variants || [], selectedOptions?.selectedProduct) :
                            <Fragment>
                                {
                                    (selectedOptions?.products || []).map((x, i) => {
                                        let isProductOutOfStock = setting?.inventory_threshold >= x?.variants[0]?.inventory_quantity;

                                        return(
                                            setting.hide_out_of_stock === 0 ? renderProducts(x, i, isProductOutOfStock) : setting.hide_out_of_stock === 1 && !isProductOutOfStock ? renderProducts(x, i, isProductOutOfStock) : ""
                                        )
                                    })
                                }
                                {isLoadingCallApi ? <div className="loading-product"><Spinner/></div>: ""}
                            </Fragment>
                    }

            </Fragment>
        )
    }

    const FilterTypeThree = () => {
        const renderProducts = (x, i, isProductOutOfStock) => {
            let newPrice = parseFloat(x.variants[0].price) - parseFloat(selectedProduct.price);
            let newPriceLess = parseFloat(selectedProduct.price) - parseFloat(x.variants[0].price);
            let allowMaxExpensive = setting.allow_max_expensive == 0 && newPrice > 0;
            return (
                <li className={`FilterTypeTwoImg ${isProductOutOfStock ? "is-disabled" : allowMaxExpensive ? "is-disabled" : ""}`} onClick={isProductOutOfStock ? null : allowMaxExpensive ? null :() => onSelectProduct(x)}>
                    <div className={"re-product-details"}>
                        <div className={"re-product-img"}>
                            <img src={x?.image?.src} alt={x?.image?.alt}/>
                        </div>
                        <div className={"re-product-detail-content"}>
                            <h2>{x.title}</h2>
                            <div className="re-product-price">
                                <span>{currencySymbol[shopDetails.currency]}{x?.variants[0]?.price}</span>
                                {
                                    allowMaxExpensive ? "" : newPrice > 0 ? <React.Fragment><Badge bg="danger" className={isProductOutOfStock || allowMaxExpensive ? "active" : ''} >+ {currencySymbol[shopDetails.currency]}{newPrice.toFixed(2)}</Badge>&nbsp;</React.Fragment> : ''
                                }
                                {
                                    0 < newPriceLess  ? <React.Fragment><Badge bg="bg-success" className={"active"}>- {currencySymbol[shopDetails.currency]}{newPriceLess.toFixed(2)}</Badge></React.Fragment> : ''
                                }
                            </div>
                        </div>
                    </div>
                    <div className={"re-product-action"}>
                        {isProductOutOfStock ? <div className="re-product-out-of-stock"><Badge bg="light">Sold out</Badge></div> : ""}
                    </div>
                </li>
            )
        }
        if(isLoadingCallApi){
            return <div className="loading-product"><Spinner/></div>
        }
        return(
            <React.Fragment>
                {
                    selectedOptions?.selectedProduct?.id ? <ul className={`re-product-list` } >{FilterTypeOne(selectedOptions?.selectedProduct?.variants || [], selectedOptions?.selectedProduct)}</ul> :
                        <Accordion>
                        {
                            (selectedOptions?.sub_filter || []).map((filter, i) => {
                                return(
                                    <Accordion.Item eventKey={`${i}`} key={i} >
                                        <Accordion.Header onClick={() => onOpenSubOption(filter, i)}>{filter.label}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <ul className="re-product-list" onScroll={() =>handleScroll(i)}>
                                                {
                                                    (filter?.products || []).map((x, i) => {
                                                        let isProductOutOfStock = setting?.inventory_threshold >= x?.variants[0]?.inventory_quantity;
                                                        return(
                                                            setting.hide_out_of_stock === 0 ? renderProducts(x, i, isProductOutOfStock) : setting.hide_out_of_stock === 1 && !isProductOutOfStock ? renderProducts(x, i, isProductOutOfStock) : ""
                                                        )
                                                    })
                                                }
                                                {isLoadingCallApiSub ? <div className="loading-product"><Spinner/></div>: ""}
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                            })
                        }
                        </Accordion>
                }
            </React.Fragment>
        )

    };

    const FilterTypeFour = () => {
        const renderSubReasons = (isDisplay) => {
            return(
                isDisplay ? <Fragment>
                    {(selectedOptions?.selectedReasons?.sub_reason || []).map((x, i) => {
                        return(
                            <li  key={i} onClick={x.is_additional_info == 1 ? () => onClickReasons(x, 'subReason') : () => onSelectReasons(x, 'subReason')}>
                                {x.label}
                                <span className={"re-option-arrow"}>{Icons.continueIcon}</span>
                            </li>
                        )
                    })}
                </Fragment> : ''
            )
        }
        return (
            <Fragment>
                {
                    selectedOptions?.selectedReasons?.id && (selectedOptions?.selectedReasons.is_additional_info == 0 && selectedOptions?.selectedReasons?.sub_reason?.length > 0 || selectedOptions?.selectedReasons.is_additional_info == 1) ?
                        <Fragment>
                            {

                                renderSubReasons(selectedOptions?.selectedReasons?.isDisplay)
                            }
                            {
                                (selectedOptions?.selectedReasons.is_additional_info == 1 || selectedOptions?.selectedReasons?.subReason?.is_additional_info == 1) &&
                                <li className={"re-reason-input"}>
                                    <Form>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label> {selectedOptions?.selectedReasons?.subReason?.is_additional_info == 1 ? selectedOptions?.selectedReasons?.subReason?.additional_info_label : selectedOptions?.selectedReasons.additional_info_label}</Form.Label>
                                            <Form.Control as="textarea" rows={3} onChange={(e) => setAdditionalInfoLabel(e.target.value)} value={additionalInfoLabel} placeholder="Type your comment here (min. 20 characters)..."/>
                                        </Form.Group>
                                    </Form>
                                    <div className={"w-100"}>
                                        <Button variant={"primary"} className={"w-100"}  disabled={additionalInfoLabel?.trim() === ""} onClick={() => onSelectReasons(selectedOptions?.selectedReasons?.subReason?.is_additional_info == 1 ? {...selectedOptions?.selectedReasons, subReason:{...selectedOptions?.selectedReasons?.subReason, additionalInfo:additionalInfoLabel}} : {...selectedOptions?.selectedReasons, additionalInfo:additionalInfoLabel}, '')}>Submit comment</Button>
                                    </div>

                                </li>
                            }
                        </Fragment> :
                        <Fragment>
                            {
                                returnExchangeReasons.map((x, i) => {
                                    let sub_reason = x.sub_reason ? JSON.parse(x.sub_reason) : [];
                                    return(
                                        <li key={i} onClick={(x.is_additional_info == 0 && sub_reason.length > 0) || (x.is_additional_info == 1) ? () => onClickReasons(x, '') : () => onSelectReasons(x, '')}>
                                            {x.label}
                                            <span className={"re-option-arrow"}>{Icons.continueIcon}</span>
                                        </li>
                                    )
                                })
                            }
                        </Fragment>
                }
            </Fragment>
        )
    }

    const onDisplayOption = (option, i) => {
        return(
            <Fragment>
                <li onClick={() => onClickOption(option)} key={i}>
                    {option.label}
                    <span className={"re-option-arrow"}>{Icons.continueIcon}</span>
                </li>
            </Fragment>
        )
    }

    const renderOptions = (x, i) => {
        return(
            <Fragment>
            {
                returnExchangeOptions.map((option, i) => {
                    let subFilter = option.sub_filter ? JSON.parse(option.sub_filter) : [];
                    let searchTag = option.search_tag ? option.search_tag : "";
                    let tagsList = selectedProduct?.tags ? selectedProduct?.tags.split(",").map((x) => x.trim()) : [];
                    let isDisplaySubFilter = false;
                    let isDisplay = option.search_tag ? tagsList.findIndex((f) => f === searchTag) === -1 ? false : true : false;
                    if(subFilter.length > 0){
                        isDisplaySubFilter = subFilter.some(r=> tagsList.includes(r.tag));
                    }
                    return(
                        <React.Fragment key={`renderOptions-${i}`}>
                            {
                                selectedOptions?.id === option.id ? option.filter_type == 1 ? FilterTypeOne(selectedProduct.variants, selectedProduct) :  option.filter_type == 2 ? FilterTypeTwo() :  option.filter_type == 3 ? FilterTypeThree() : option.filter_type == 4 ? FilterTypeFour() : '' :
                                    selectedOptions?.id ? "" : onDisplayOption(option, i)
                            }
                        </React.Fragment>
                    )
                })
            }
        </Fragment>
        )
    }
    return (
        <div className="re-order-content">
            <OrderHeader  shopDetails={shopDetails} returnAndExchangeData={returnAndExchangeData} isDisplay={true} onLogOut={onLogOut} onReview={onReview} logo={setting.logo}/>
            <div className="re-content-area">
                <div className="re-main-content">
                    <Container className="d-flex justify-content-start justify-content-md-center">
                        <div className={"re-wrapperRef"} onClick={handleClickOutside}/>
                        <div className={"re-main "}>

                                <div className={"re-card"} >
                                    {
                                        isLoading ? <div className={"re-loading"}><Spinner/></div> :  <Fragment>
                                            <div className={"re-card-header"}>
                                                <div className="re-order-no"><p>Order #{order?.order_number}</p><span>(returnable until  {moment(order?.created_at).add(Number(setting?.return_days || 1), 'days').format("DD MMMM YYYY")})</span></div>
                                                <div className="re-order-title"><h2>Choose a product to return or exchange</h2></div>
                                                {
                                                    status == 1 ?  <Alert  variant={"primary"} className={"mt-3 mb-0"}>
                                                        A return for order #{order.order_number} has already been submitted.
                                                    </Alert> : ""
                                                }
                                                {
                                                    order?.fullfillment_status == null ?
                                                        <Alert  variant={"primary"} className={"mt-3 mb-0"}>
                                                            No products are eligible for return at this time, because your order is not shipped yet.
                                                        </Alert> : ""
                                                }
                                            </div>
                                            <div className={"re-card-content"}>
                                                <ul className={"re-order-product"}>
                                                    {
                                                        (order?.line_items || []).map((x, i) => {
                                                            let currencyCode = x?.price_set?.presentment_money?.currency_code;
                                                            let finalPrice = null;
                                                            if(x?.discount_allocations?.length > 0){
                                                                const discountAllocationPrice = x.discount_allocations[0].amount;
                                                                finalPrice = parseFloat(x.price) - parseFloat(discountAllocationPrice)
                                                            }
                                                            const findVariant = (x?.variants || []).find((y) => y.id == x.variant_id);
                                                            const findImage = (x?.images || []).find((y) => y.id == findVariant.image_id);
                                                            return(
                                                                <li key={x.return_exchange_product_id} id={x.return_exchange_product_id}
                                                                    className={order?.fullfillment_status == null || x.isFulFill === false ? "re-product-disabled" :  ((status === "1" || status === "2") &&  x?.type  && (x?.isExchange || x?.isReturn) ) ? "re-product-disabled" :selectedProduct?.id === x.id && selectedProduct.return_exchange_product_id === x.return_exchange_product_id && x.isFulFill ? "active" : "" }
                                                                >
                                                                    <div className={"re-product-details"} key={i} onClick={(status == 1 && (x?.isExchange || x?.isReturn)) || order?.fullfillment_status == null || x.isFulFill === false ? null : x?.optionAndReason || x?.type == 0 ? () => onModalOpen(x) : null}>
                                                                        <div className={"re-product-checkbox"}>
                                                                            <Form.Check
                                                                                type={"checkbox"}
                                                                                id={`default-checkbox`}
                                                                                checked={x?.isDisplayOptionAndReason || (status == 1 && (x?.isExchange || x?.isReturn))}
                                                                                onClick={x?.isDisplayOptionAndReason ? () => onRemove(x, i) : null}
                                                                            />
                                                                        </div>
                                                                        <div className={"re-product-img"} onClick={(status == 1 && (x?.isExchange || x?.isReturn)) || order?.fullfillment_status == null || x.isFulFill === false ? null : x?.optionAndReason || x?.type == 0 ? () => onModalOpen(x) : null} >
                                                                            <img src={findImage?.src ? findImage?.src : x.image} alt={findImage?.src ? findImage?.src : x.image}/>
                                                                        </div>
                                                                        <div className={"re-product-detail-content"} onClick={(status == 1 && (x?.isExchange || x?.isReturn)) || order?.fullfillment_status == null || x.isFulFill === false ? null : x?.optionAndReason || x?.type == 0 ? () => onModalOpen(x) : null} >
                                                                            <h2>{x.title}</h2>
                                                                            {
                                                                                x.variant_title ?<p className="re-product-variant" >
                                                                                        <Fragment>
                                                                                            {(x?.options || []).map((op, opInd) => {return(
                                                                                                <Fragment>{op.name} {x?.options.length === (opInd + 1) ? ": " : '/ '}</Fragment>
                                                                                            )})}
                                                                                            {x.variant_title}
                                                                                        </Fragment>
                                                                                    </p>
                                                                                    : ""
                                                                            }
                                                                            {status == 2 && ((x?.isExchange || x?.isReturn) &&  x.type != 0) ? <p className="re-product-price">Reason: Product has been already {x?.isExchange ? "exchanged" :x?.isReturn ? "returned" : ""}</p> :  x.isFulFill ? finalPrice !== null ? <React.Fragment><p className="re-product-price">{currencySymbol[shopDetails.currency]}{finalPrice.toFixed(2)}</p></React.Fragment> :  <p className="re-product-price">{currencySymbol[shopDetails.currency]}{parseFloat(x.price).toFixed(2)}</p> : <p className="re-product-price">Reason: Product not fulfilled</p>}

                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        x.isFulFill ? <div className={"re-product-action"} onClick={((status == 1 || status == "2") && (x?.isExchange || x?.isReturn)) || order?.fullfillment_status == null || x.isFulFill === false ? null : x?.optionAndReason || x?.type == 0 ? () => onModalOpen(x) : null} key={i}>
                                                                            {
                                                                                ((status == 1 || status == 2)  && (x?.isExchange || x?.isReturn) &&  x.type != 0) ? <Badge bg="success">{x?.isExchange ? "Exchanged" : x?.isReturn ? "Returned" :  ""}</Badge> :((x?.isDisplayOptionAndReason || x?.type != 0) && (x?.isExchange || x?.isReturn)) ? <Badge bg="primary">{x?.isExchange ? "Exchange" : x?.isReturn ? "Return" :  ""}</Badge> : ""
                                                                            }
                                                                            {
                                                                                ((status == 1 || status == 2) && (x?.isExchange || x?.isReturn)) ? "" :  <span>{Icons.continueIcon}</span>
                                                                            }

                                                                        </div> :  <div className={"re-product-action"}><Badge bg="secondary">Ineligible</Badge></div>
                                                                    }

                                                                    {selectedProduct?.id === x.id && selectedProduct.return_exchange_product_id === x.return_exchange_product_id && x.isFulFill ?
                                                                        <div className={"re-options-content"}>
                                                                            {
                                                                                selectedOptions.filter_type == 1 ? selectedOptions?.selectedVariant?.id ?
                                                                                    <div className={"re-option-header"}><div className={"re-option-header-back-btn"} onClick={selectedOptions?.selectedReasons?.id ? onBackReasons : () => onBackOption("variant")}>{Icons.backIcon}</div><h6>What is the reason for the exchange?</h6></div> :
                                                                                    <div className={"re-option-header"}><div className={"re-option-header-back-btn"} onClick={() => onBackOption("option")}>{Icons.backIcon}</div><h6>{selectedOptions.label}</h6></div> :
                                                                                    selectedOptions.filter_type == 2 ? <div className={"re-option-header"}><div className={"re-option-header-back-btn"} onClick={selectedOptions?.selectedReasons?.id ? onBackReasons : () => onBackOption(selectedOptions?.selectedVariant?.id ? "variant" : selectedOptions?.selectedProduct?.id ? "product" :  "option")}>{Icons.backIcon}</div>{selectedOptions?.selectedProduct?.id ? selectedOptions?.selectedVariant?.id ? <h6>What is the reason for the exchange?</h6> : <h6>Choose a {selectedOptions?.selectedProduct?.options.map((o, ind) => {return(<Fragment>{o.name}{(ind + 1) == selectedOptions?.selectedProduct?.options.length ? "": " / "}</Fragment>)})}</h6> :<h6>{selectedOptions.label}</h6> }</div> :
                                                                                        selectedOptions.filter_type == 3  ?  <div className={"re-option-header"}><div className={"re-option-header-back-btn"} onClick={selectedOptions?.selectedReasons?.id ? onBackReasons : () => onBackOption(selectedOptions?.selectedVariant?.id ? "variant" : selectedOptions?.selectedProduct?.id ? "product" :  "option")}>{Icons.backIcon}</div>{selectedOptions?.selectedProduct?.id ? selectedOptions?.selectedVariant?.id ? <h6>What is the reason for the exchange?</h6> : <h6>Choose a {selectedOptions?.selectedProduct?.options.map((o, ind) => {return(<Fragment>{o.name}{(ind + 1) == selectedOptions?.selectedProduct?.options.length ? "": " / "}</Fragment>)})}</h6> :<h6>{selectedOptions.label}</h6> }</div> :
                                                                                            <div className={"re-option-header"}><h6>What do you want to do?</h6></div>
                                                                            }
                                                                            {
                                                                                selectedOptions.filter_type == 2 ?  <ul className={"re-product-list" } ref={scrollRef}>
                                                                                    {renderOptions(x, i)}</ul>: selectedOptions.filter_type == 3 ? renderOptions(x, i) : <ul className={`re-product-list` }>{renderOptions(x, i)}</ul>
                                                                            }
                                                                        </div>
                                                                        : ""}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </Fragment>
                                    }
                                </div>

                        </div>

                    </Container>
                </div>
                <Footer/>
            </div>
        </div>
    );
};

export default Order;