import React from 'react';
import {useSelector} from "react-redux";

const Footer = () => {
    const shopDetails = useSelector((state) => state.shopDetails);
    return (
        <div className="re-footer-content">
            <span> Need help? <strong>{shopDetails?.support_email}</strong></span>
        </div>
    );
};

export default Footer;