import React from "react";
import {Navigate, Outlet} from 'react-router-dom';
import {baseUrl, getToken, shopName} from "../../constent";


const PrivateRoute = () => {

    return (
        getToken() ? <Outlet/> : <Navigate to={`${baseUrl}/${shopName}/login`} />
    );
};

export default PrivateRoute;
