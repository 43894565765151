import { SET_SHOP_DETAILS, } from '../constent';

const initialState = {
    id:"",
    shop_id:"",
    app_title:"",
    language:"",
    support_email:"",
    customer_login_type:"",
    logo:"",
    bg_image:"",
    favicon:"",
    primary_color:"",
    secondary_color:"",
    bg_color:"",
    product_background_color:"",
    primary_font_family:"",
    secondary_font_family:"",
    currency: "",
    custom_text:"Set the additional payment instructions for a customer if they exchange a product for something more expensive. Use '{{ email }}' to fill the users' email address into the text.",
};

export default function shopDetailsReducer(state = initialState, action) {
    switch (action.type) {
        case SET_SHOP_DETAILS: {
            return {
                ...state,
                ...action.payload,
            }
        }
        default: {
            return state
        }
    }
}
