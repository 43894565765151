import React,{Fragment} from 'react';
import {Button} from "react-bootstrap";
import { currencySymbol,} from "../../constent";
const OrderHeader = ({shopDetails, returnAndExchangeData, isDisplay, onReview, onLogOut, logo}) => {

    const finalPrice =  Math.abs((parseFloat(returnAndExchangeData?.exchangeProductPriceTotal || 0) - parseFloat(returnAndExchangeData?.returnProductPriceTotal || 0)))
    return (
        <div className="re-order-header">
            <div className={"re-header-row"}>
                <div className={"re-header-col-back"}>
                    <Button variant={"light"} onClick={onLogOut}>← Exit</Button>
                </div>
                <div className={"re-header-logo"}>
                    <img src={logo}/>
                </div>
                <div className={"re-header-col-action"}>
                    {
                        isDisplay ? <Fragment>
                            <div className={"d-none d-lg-flex gap-4 "}>
                                {
                                    returnAndExchangeData.returnCount + returnAndExchangeData.exchangeCount > 0 ?
                                        <div className={"header-product-count"}>
                                            <h6>{(returnAndExchangeData.returnCount + returnAndExchangeData.exchangeCount)} product selected</h6>

                                            <p>{(parseFloat(returnAndExchangeData.exchangeProductPriceTotal) - parseFloat(returnAndExchangeData.returnProductPriceTotal)) > 0 ? "Total additional payment: " : 'Total refund: '}
                                                <span className="re-item-card-price-discount">
                                                            {currencySymbol[shopDetails.currency]}
                                                    {finalPrice.toFixed(2)}
                                                </span>
                                            </p>

                                        </div> : ""
                                }

                                <Button variant={"primary"} disabled={returnAndExchangeData.returnCount + returnAndExchangeData.exchangeCount > 0 ? false : true} onClick={onReview}>Continue with return</Button>
                            </div>
                            <div className={"d-flex d-lg-none"}>
                                <Button variant={"primary"} disabled={returnAndExchangeData.returnCount + returnAndExchangeData.exchangeCount > 0 ? false : true} onClick={onReview}>Continue</Button>
                            </div>
                        </Fragment> : ""
                    }
                </div>
            </div>
        </div>
    );
};

export default OrderHeader;